<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import LoadingSpinner from "@/components/Loading";
// import InputGroup from "@/components/newforms/input-group";
import SelectGroup from "@/components/newforms/select-group";
// import UserType from "@/components/newforms/user-type";
// import StateOption from "@/components/forms/state-option";
import MessageModal from "@/components/modals/message-modal";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
// import "vue2-datepicker/index.css";

export default {
  name: "Edit_tenancy",
  components: {
    Layout,
    PageHeader,
    // InputGroup,
    SelectGroup,
    // UserType,
    MessageModal,
    ConfirmationModal
  },
  data() {
    return {
      title: `Tenancy Add`,
      items: [
        {
        text: "Tenancy",
        },
        {
        text: "Add",
        active: true,
        },
      ],
      member_options: [],
      member_selected: ""
    };
  },
  methods: {
    async loadMemberOptions() {
      await axios.get(`${process.env.VUE_APP_APIURL}/member-list`, {
        headers: authHeader(),
      }).then((e) => {
        if (e.data.code == 'success') {
          this.member_options = e.data.data.map(e => {return {text:e.full_name, value: e.id}});
        }
      }).catch( function (error) {
        console.log("get amenity options:" + error.message);
      });
    },
    async createTenancy() {
      if (this.member_selected == "")
      return;
    
      let member_name = this.member_options.find(e =>{ return e.value == this.member_selected});
      this.$refs.confirmationModal.showModal(
      `This action will create an empty draft tenancy record for the member: ${member_name.text}` 
      , async() => {
        const result = await axios.post(`${process.env.VUE_APP_APIURL}/tenancy/create-tenancy`, {member_id: this.member_selected}, {
          headers: authHeader(),
        }).catch( function (error) {
          console.log("fail:" + error.message);
        });
        if (result.data.code == 'success') {
          this.$router.push({name: "Tenancy View New", params: {id: result.data.data.id}})
        }
      }); 
      // await axios.get(`${process.env.VUE_APP_APIURL}/member-list`, {
      //   headers: authHeader(),
      // }).then((e) => {
      //   if (e.data.code == 'success') {
      //     this.member_options = e.data.data.map(e => {return {text:e.full_name, value: e.id}});
      //   }
      // }).catch( function (error) {
      //   console.log("get amenity options:" + error.message);
      // });
    },
  },
  computed: {
   
  },
  async created() {
    await this.loadMemberOptions();
  },
  mounted() {
  },
  watch: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <MessageModal ref="messageModal" />
    <ConfirmationModal ref="confirmationModal"/>
    <!-- <LoadingSpinner :isLoading="false" />
    <MessageModal ref="messageModal" @CloseBtn="redirectPage" /> -->
    <div class="card m-auto" style="max-width: 500px;">
      <div class="card-body">
        <h4 class="subTitle-font pb-0 mb-3">Create A Tenancy For:</h4>
        <SelectGroup id="member" name="member" v-model.trim="member_selected" label="Select Member" :options="member_options"/>
        <button class="btn btn-orange mt-3 w-100" @click="createTenancy">Create</button>
        <router-link
          class="btn btn-filter mt-2 w-100"
          :to="{ name: 'Tenancy'}">
          Back
        </router-link>
      </div>
    </div> 
  </Layout>
</template>
